import { FC } from 'react'
import { breakpoints, styled, useMediaQuery } from '@liftfoils/styles'
import { SectionHalfImageProps } from '@liftfoils/models'
import { PortableText, Media, MediaPlaceholder } from '@liftfoils/components'

const Wrap = styled('div', {
  position: 'relative',
  display: 'grid',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
  },
})

const MediaWrap = styled('div', {
  '@md': {
    minHeight: '95vh',
  },
})

const ContentWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  $hasContainerMX: true,
  wordBreak: 'break-word',
  $py: 'L',
  textAlign: 'center',
  '@md': {
    $py: 'XL',
    textAlign: 'left',
    maxWidth: '550px',
  },
  '@xl': {
    mx: 'auto',
  },
})

const Heading = styled('h1', {
  lift_font: 'caps02',
  color: '$gray500',
})
const Description = styled('div', {
  $mt: 'M',
  lift_font: 'heading03',
  color: '$gray300',
  '.highlighted': {
    color: '$gray500',
  },
  '& > div > p:empty': {
    height: '$8',
  },
})

export const SectionHalfImage: FC<SectionHalfImageProps> = ({
  title,
  description,
  media,
  priority,
}) => {
  const isMd = useMediaQuery(breakpoints['md'])
  return (
    <Wrap>
      <MediaWrap>
        <MediaPlaceholder ratio={isMd ? 'none' : 'portrait2'}>
          <Media
            {...media}
            layout="fill"
            sizes="(min-width: 740px) 50vw, 100vw"
            priority={priority}
            alt={media?.alt ?? ''}
          />
        </MediaPlaceholder>
      </MediaWrap>
      <ContentWrap>
        {title && <Heading>{title}</Heading>}
        {description && (
          <Description>
            <PortableText value={description} />
          </Description>
        )}
      </ContentWrap>
    </Wrap>
  )
}
